const URI_QUERY_PARAMS_REGEX = '(?:\\?.+)?';
const URI_HASH_PARAMS_REGEX = '(?:#.*)?';
const URI_PATH_EXTENSION_REGEX = '$';

export type UriMatcherOptions = {
    allowPathExtensions: boolean,
    allowQueryParams: boolean,
    allowHashParams: boolean,
};

export default function UriMatcher(uri: string, matcherOptions: Partial<UriMatcherOptions> = {}) {
    const options: UriMatcherOptions = {
        allowPathExtensions: false,
        allowQueryParams: true,
        allowHashParams: true,
        ...matcherOptions,
    };
    const queryParamsComponent = options.allowQueryParams ? URI_QUERY_PARAMS_REGEX : '';
    const hashParamsComponent = options.allowHashParams ? URI_HASH_PARAMS_REGEX : '';
    const pathExtensionComponent = options.allowPathExtensions ? '' : URI_PATH_EXTENSION_REGEX;

    return `${uri}${queryParamsComponent}${hashParamsComponent}${pathExtensionComponent}`;
}
