import { FullScreenSheetPlacement, PlacementType } from '@amzn/showtime';
import UriMatcher from '../../UriMatcher';

export const id = 'myhr-fab';

const placement: FullScreenSheetPlacement = {
    id,
    uri: UriMatcher('//.*amazon(\\.work|\\.com)/myhr-fab', { allowPathExtensions: true }),
    title: 'MyHR Help',
    type: PlacementType.FullScreenSheet,
};

export default placement;
