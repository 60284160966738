import type { MobileTenantConfig } from '@amzn/showtime';

const mobileTenantConfig: MobileTenantConfig = {
    awsAccountId: '707668831718',
    bindleId: 'amzn1.bindle.resource.totd2fyhovrglr6o2zlq',
    cti: {
        category: 'PXT',
        type: 'ESTech-LiveHelp',
        item: 'General',
    },
    emailList: 'team-targaryen@amazon.com',
    npmPackageName: '@amzn/atozmobile-myhr-fab',
    posixGroup: 'team-targaryen',
    projectName: 'AtoZMyHRFAB',
    tenantId: 'MyHRFAB',
};

export default mobileTenantConfig;
