import { Placement } from '@amzn/showtime';
import { hasMyHRFABFeature } from '../features';
import FABFullPage from './FABFullPage';

const getPlacements = (features: string[]) => {
    const placements: Placement[] = [];
    if (hasMyHRFABFeature(features)) {
        placements.push(FABFullPage);
    }
    return placements;
};

export default getPlacements;
